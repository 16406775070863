<template>
    <div class="modal is-active">
        <div class="modal-background"></div>

        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Client Login</p>
                <button class="delete" aria-label="close" @click="cancel"></button>
            </header>
            <section class="modal-card-body">
                <b-field label="Find a Client">
                    <input placeholder="Search" ref="search" type="search" class="input is-small" v-model="searchTerm" :class="{ 'loading': isLoading }" />               
                </b-field>
                <b-table :loading="isLoading"
                         v-if="data && data.length > 0"
                         :data="data">
                    <b-table-column field="username" label="Username" v-slot="props">
                        {{props.row.username}}
                    </b-table-column>
                    <b-table-column field="email" label="Email" v-slot="props">
                        {{props.row.email}}
                    </b-table-column>
                    <b-table-column field="firstName" label="Name" v-slot="props">
                        {{props.row.firstName}} {{props.row.lastName}}
                    </b-table-column>
                    <b-table-column field="company" label="Company" v-slot="props">
                        {{props.row.company}}
                    </b-table-column>
                    <b-table-column field="role" label="Role" v-slot="props">
                        {{props.row.role}}
                    </b-table-column>
                    <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
                        <div class="buttons">
                            <b-tooltip label="Login" position="is-left">
                                <button class="button is-small is-primary" type="button" @click="rowActions(props.row,'Login')">
                                    <b-icon icon="login" size="is-small" />
                                </button>
                            </b-tooltip>
                            <b-tooltip label="Reset Password" position="is-left">
                                <button class="button is-small is-danger ml-1" type="button" @click="rowActions(props.row,'ResetPassword')">
                                    <b-icon icon="lock-reset" size="is-small" />
                                </button>
                            </b-tooltip>
                        </div>
                    </b-table-column>
                </b-table>
                <div v-if="data && data.length === 0">
                    No users found
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="cancel">Close</button>
            </footer>
        </div>
    </div>
</template>

<script>
    import Axios from 'axios'

    export default {
        name: 'ClientUserLoginModal',
        components: {},
        props: {
            isActive: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isLoading: false,
                isFullPage: true,
                username: null,
                data: null,
                selected: null,
                isFetching: false,
                timeout: null,
                debouncedsearchTerm: ''
            }
        },
        computed: {
            searchTerm: {
                get: function() {
                    return this.debouncedsearchTerm
                },
                set: function (val) {
                    if (this.timeout) clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {
                        this.debouncedsearchTerm = val
                    }, 400)
                }
            }
        },
        mounted() {
            this.focusInput();
        },
        methods: {
            clientLogin(row) {
                this.isLoading = true
                var that = this

                //call end point
                this.$axiosAPI.post(`authenticate/${row.username}`).then((response) => {
                    that.$store.commit('resetLogin');
                    that.$store.commit('user', response.data);
                    
                    that.$store.dispatch('loadClientUserTitles', that.$axiosAPI).then(function (HasTitles) {
                        if (HasTitles)
                            window.location.href = HasTitles ? "/" : "/no-titles";
                        else {
                            that.$router.push({ name: 'NoTitles' });
                            that.cancel();
                        }
                    });

                }).catch((error) => {
                    that.isLoading = false
                    var errorMessage = error.response.data; //error.message if not set?
                    that.$buefy.snackbar.open(errorMessage);
                })
            },
            getAsyncData(name) {
                if (name === null) {
                    this.data = []
                    return
                }
                this.isLoading = true
                this.$axiosAPI.get(`client-user-login-search/${name}`)
                    .then(({ data }) => {
                        this.data = data
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            cancel() {
                this.$emit('close')
            },
            rowActions(row, buttonAction) {
                var that = this

                switch (buttonAction) {
                    case 'ResetPassword':
                        this.$buefy.dialog.confirm({
                            title: 'Reset Password',
                            message: 'Are you sure you want to <b>reset</b> the password for ' + row.firstName + ' ' + row.lastName + '? (' + row.username + ') <br/><br/> Once done they will receive a password reset email.',
                            confirmText: 'Reset Password',
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: () => {
                            this.$axiosAPI.post(`client-user/${row.id}/reset-password`).then(() => {
                                        that.isLoading = false
                                        that.$buefy.toast.open('Email Sent!')
                                }).catch((error) => {
                                    that.$buefy.snackbar.open(error)
                                    that.isLoading = false
                                })
                               
                            }
                        })
                        break
                    case 'Login':
                        this.clientLogin(row)
                        break
                }
            },
            focusInput() {
                this.$refs.search.focus();
            }
        },
        watch: {
           
            debouncedsearchTerm(newValue) {
                if (newValue === "") {
                    this.data = []
                    this.debouncedsearchTerm = null
                }
                else if(newValue !== null && newValue.length >= 2) {
                    this.getAsyncData(newValue);
                }

            },
        }
    }
</script>

<style scoped>
    .icon-size {
        font-size: 100px;
        color: #363636;
    }
    .button-theme-color {
        background-color: #0092A8;
        color: white;
    }
    .modal-card {
        width:fit-content;
    }

    .modal-card-body .modal-card-foot {
        overflow: visible !important;
    }
</style>