import Vue from 'vue';
import Buefy from 'buefy'
import '@/scss/setup.scss'

import App from './App.vue';
//import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import Axios from 'axios'
import * as dayjs from 'dayjs'
//import AuthService from '@/services/auth-service'
import Clipboard from 'v-clipboard'
import ReadMore from 'vue-read-more';

var customParseFormat = require('dayjs/plugin/customParseFormat')
var dayjsUtc = require('dayjs/plugin/utc')
var dayjsTimezone = require('dayjs/plugin/timezone')
dayjs.extend(customParseFormat)
dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)

Vue.config.devtools = true
Vue.config.productionTip = true;
Vue.prototype.$dayjs = dayjs;

Vue.directive('visible', function (el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
});

//SETUP AXIOS

// Create a base instance with common configuration
const axiosAPI = Axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT
});

Vue.prototype.$axiosAPI = axiosAPI;


// Create another instance with different configuration
const axiosBase = Axios.create({});

Vue.prototype.$axiosBase = axiosBase;

axiosAPI.interceptors.request.use(
    async config => {

        //var steve2 = "2asdasda";

        //if (config.url.indexOf("blob.core.windows.net") >= 0) {
        //    var steve = "2asdasda";
        //    return config; //if we directly uploading to azure dont do anything special
        //}

        var user = store.state.user;
        var token = user !== null && user.accessToken !== undefined ? user.accessToken : "";
        config.headers = {
            'Authorization': `Bearer ${token}`
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

// Response interceptor for API calls
axiosAPI.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    
    if (error.response) {
        // Request made and server responded
        var isAuthorisationError = error.response.status === 403 || error.response.status === 401;

        if (isAuthorisationError && !originalRequest._retry) {
            originalRequest._retry = true;

            await refreshAccessToken();

            var user = store.state.user;
            var token = user !== null && user.accessToken !== undefined ? user.accessToken : "";
            axiosAPI.defaults.headers.common['Authorization'] = 'Bearer ' + token;

            if (originalRequest.data && typeof originalRequest.data === 'string')
                originalRequest.data = JSON.parse(originalRequest.data); //otherwise the content type is set as text not json on second attempt

            return axiosAPI(originalRequest);
        }

    } else if (error.request) {
        // The request was made but no response was received
    } else {
        // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
});

const defaultDocumentTitle = 'Portal'
router.afterEach(to => {


    if (to.meta.title) {
        document.title = `${to.meta.title} | ${defaultDocumentTitle}`
    } else {
        document.title = defaultDocumentTitle
    }
})

Vue.use(ReadMore)
Vue.use(Clipboard)
Vue.use(Buefy)
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');


Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}


async function refreshAccessToken() {
    var refreshTokenRequest = {}

    var user = store.state.user;

    var res;
    if (user && user.refreshToken) {
        refreshTokenRequest.refreshToken = user.refreshToken;

        var path = router.app.$route.path;
        await store.dispatch('refreshToken', { key: refreshTokenRequest, axios: axiosAPI }).then(res => {
            return res
        }).catch(error => {
            window.sessionStorage.clear()
            router.push({ name: 'LoginView', query: { redirecturl: path } });
            return null;
        });

    } else {
        window.sessionStorage.clear()
        router.push({ name: 'LoginView', query: { redirecturl: path } });
        return null;
    }
}