import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import MasterPageMain from '../views/Masterpages/MainView.vue'

Vue.use(Router)

//TODO: use the mixin permissions arrays? or similar? as moment if someone who shouldnt tries access a page they will actually go to it
var generalAccess = ['admin', 'Standard', 'restrictedtitles', 'externalstore', 'client', 'customerservice', 'dpopublisher', 'reporting', 'production', 'financial', 'partner', 'publisher', 'introducer'];
var adminOnlyAccess = ['admin'];
var magazineAccess = ["admin", "partner", "client", "restrictedtitles", "publisher", "production", "customerservice"];


const routes = [
    //no master pageredirecturl
    {
        meta: {
            title: 'Login',
            authType: generalAccess,
            noAuth: true
        },
        path: '/login',
        name: 'LoginView',
        component: () => import(/* webpackChunkName: "main" */ '../views/Home/LoginView.vue')
    },
    {
        meta: {
            title: 'Reset Password',
            authType: generalAccess,
            noAuth: true
        },
        path: '/reset-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "main" */ '../views/Authentication/ForgotPassword.vue'),
        props: true
    },
    {
        meta: {
            title: 'Admin Login',
            authType: generalAccess,
            noAuth: true
        },
        path: '/login-admin',
        name: 'LoginAdmin',
        component: () => import(/* webpackChunkName: "main" */ '../views/Authentication/LoginAdmin.vue')
    },
    //under the master page
    {
        path: '/',
        component: MasterPageMain,
        meta: {
            title: 'Default',
            authType: generalAccess,
        },
        redirect: () => titlePageRedirect('Dashboard'),
        children: [

            {
                meta: {
                    title: 'Dashboard',
                    authType: generalAccess,
                },
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import(/* webpackChunkName: "main" */ '../views/Home/HomeView.vue'),
                props: true
            },
            {
                meta: {
                    title: 'No Titles Access'
                },
                path: '/no-titles',
                name: 'NoTitles',
                component: () => import(/* webpackChunkName: "main" */ '../views/Home/NoTitlesAccess.vue'),
                props: true
            },
            {
                meta: {
                    title: 'No Title Access'
                },
                path: '/no-title-access',
                name: 'NoTitleAccess',
                component: () => import(/* webpackChunkName: "main" */ '../views/Home/NoTitleAccess.vue'),
                props: true
            },
            {
                meta: {
                    title: 'News'
                },
                path: '/news-feed',
                name: 'NewsFeed',
                component: () => import(/* webpackChunkName: "main" */ '../views/Home/NewsFeed.vue'),
                props: true
            },
            {
                meta: {
                    title: 'My Profile',
                    authType: generalAccess,
                },
                path: '/my-profile',
                name: 'MyProfile',
                component: () => import(/* webpackChunkName: "main" */ '../views/Authentication/MyProfile.vue'),
                props: true
            },
            {
                meta: {
                    title: 'User Managment',
                    authType: generalAccess,
                },
                path: '/account/user-management',
                name: 'UserManagement',
                component: () => import(/* webpackChunkName: "client" */ '../views/MyAccounts/UserManagement.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Contact List',
                    authType: generalAccess,
                },
                path: '/account/contact-list',
                name: 'ContactList',
                component: () => import(/* webpackChunkName: "client" */ '../views/MyAccounts/ContactList.vue'),
                props: true
            },

            {
                meta: {
                    title: 'Legacy Site',
                    authType: generalAccess,
                },
                path: '/legacy-site',
                name: 'OldPortalRedirect',
                component: () => import(/* webpackChunkName: "marketing" */ '../views/Home/OldPortalRedirect.vue'),
                props: true
            },
            //ADMIN ROUTES

            //MY MAGAZINES
            {
                meta: {
                    title: 'My Issues',
                    authType: magazineAccess,
                },
                path: '/title/:titleId/my-issues',
                name: 'MyIssues',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/MyIssues.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Schedule',
                    authType: magazineAccess,
                },
                path: '/title/:titleId/schedule/edit',
                name: 'EditSchedule',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/EditSchedule.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Bulk Schedule Upload',
                    authType: magazineAccess,
                },
                path: '/schedule/bulk',
                name: 'ScheduleBulk',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/ScheduleBulkInsert.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Bulk Issue Upload',
                    authType: magazineAccess,
                },
                path: '/bulk-upload',
                name: 'BulkUploader',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/FileCollector.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Category Management',
                    authType: adminOnlyAccess,
                },
                path: '/category-managment',
                name: 'CategoryManagement',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/CategoryManagement.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Customer Admin',
                    authType: magazineAccess,
                },
                path: '/customer-admin',
                name: 'CustomerAdmin',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/CustomerAdmin.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Ingest Subscribers',
                    authType: magazineAccess,
                },
                path: '/ingest-subscribers',
                name: 'IngestSubscribers',
                component: () => import(/* webpackChunkName: "main" */ '../views/Magazines/IngestSubscribers.vue'),
                props: true
            },

            // REPORTS SECTION
            {
                meta: {
                    title: 'Reports',
                    authType: generalAccess,
                },
                path: '/reports',
                name: 'ReportsView',
                component: () => import(/* webpackChunkName: "reports" */ '../views/Reports/ReportsView.vue'),
                props: true
            },
            {
                meta: {
                    title: 'All Reports',
                    authType: generalAccess,
                },
                path: '/reports/all',
                name: 'AllReportsView',
                component: () => import(/* webpackChunkName: "reports" */ '../views/Reports/AllReportsView.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Period Breakdown',
                    authType: generalAccess,
                },
                path: '/reports/period-breakdown',
                name: 'PeriodBreakdown',
                component: () => import(/* webpackChunkName: "reports" */ '../views/Reports/PeriodBreakdown.vue'),
                props: true
            },

            //FINANCE / PAYMENTS Section
            {
                meta: {
                    title: 'Statements',
                    authType: generalAccess,
                },
                path: '/finance/statements',
                name: 'StatementsView',
                component: () => import(/* webpackChunkName: "finanical" */ '../views/Payments/StatementsView.vue'),
                props: true
            },

            //OTHER SECTIONS?
            {
                meta: {
                    title: 'Offer Creator',
                    authType: generalAccess,
                },
                path: '/marketing/offer-creator',
                name: 'OfferCreator',
                component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing/OfferCreator.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Offer Creators',
                    authType: generalAccess,
                },
                path: '/marketing/offer-creator-redirect',
                name: 'OfferCreatorRedirect',
                component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing/OfferCreatorRedirect.vue'),
                props: true
            },

            {
                meta: {
                    title: 'Add Offer',
                    authType: generalAccess,
                },
                path: '/marketing/offer-creator/add-offer/:offerId',
                name: 'OfferCreatorFlow',
                component: () => import(/* webpackChunkName: "marketing" */ '../components/templates/OfferCreatorFlow.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Lead Generation',
                    authType: generalAccess,
                },
                path: '/title/:titleId/marketing/lead-generation',
                name: 'LeadGeneration',
                component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing/LeadGeneration.vue'),
                props: true
            },

            //RESOURCES SECTION
            {
                meta: {
                    title: 'Features',
                    authType: generalAccess,
                },
                path: '/features',
                name: 'FeaturesView',
                component: () => import(/* webpackChunkName: "reports" */ '../views/Resources/FeaturesView.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Logos & Artwork',
                    authType: generalAccess,
                },
                path: '/logos-artwork',
                name: 'LogosArtworkView',
                component: () => import(/* webpackChunkName: "reports" */ '../views/Resources/LogosArtworkView.vue'),
                props: true
            },

            //PROMOTIONS SECTION - BRANDED APPS
            {
                meta: {
                    title: 'Affiliate Program',
                    authType: generalAccess,
                },
                path: '/marketing/affiliate-program',
                name: 'AffiliateProgram',
                component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing/AffiliateProgram.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Push Messaging',
                    authType: generalAccess,
                },
                path: '/title/:titleId/app/push-messaging',
                name: 'PushMessaging',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/PushMessaging.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Text Only In App Messaging',
                    authType: generalAccess,
                },
                path: '/title/:titleId/app/text-only-inapp-messaging',
                name: 'TextOnlyInAppMessaging',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/TextOnlyInAppMessaging.vue'),
                props: true
            },
            {
                meta: {
                    title: 'In-App Messaging',
                    authType: generalAccess,
                },
                path: '/title/:titleId/app/inapp-messaging',
                name: 'RichInAppMessaging',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/RichInAppMessaging.vue'),
                props: true
            },
            {
                meta: {
                    title: 'App - Homepage Banners',
                    authType: generalAccess,
                },
                path: '/title/:titleId/app/homepage-banners',
                name: 'HomepageBanners',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/HomepageBanners.vue'),
                props: true
            },
            {
                meta: {
                    title: 'App - Homepage Layout',
                    authType: generalAccess,
                },
                path: '/title/:titleId/app/layout',
                name: 'AppSimulator',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/AppSimulator.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Campaign Link Builder',
                    authType: generalAccess,
                },
                path: '/title/:titleId/marketing/campaign-link-builder',
                name: 'CampaignLinkBuilder',
                component: () => import(/* webpackChunkName: "apps" */ '../views/Promotions/CampaignLinkBuilder.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Hidden Offers',
                    authType: generalAccess,
                },
                path: '/promotions/hidden-offers',
                name: 'HiddenOffers',
                component: () => import(/* webpackChunkName: "apps" */ '../views/Promotions/HiddenOffers.vue'),
                props: true
            },
            {
                meta: {
                    title: 'Screenshot Generator',
                    authType: generalAccess,
                },
                path: '/title/:titleId/screenshot-generator',
                name: 'ScreenshotGenerator',
                component: () => import(/* webpackChunkName: "apps" */ '../views/BrandedApp/ScreenshotGenerator.vue'),
                props: true
            },
            //REDIRECTS for left navigation

            
            {
                name: "MyIssuesBase",
                path: '/my-issues',
                redirect: () => titlePageRedirect('MyIssues')
            },
            {
                name: "EditScheduleBase",
                path: '/schedule/edit',
                redirect: () => titlePageRedirect('EditSchedule')
            },
            {
                name: "PushMessagingBase",
                path: '/promotions/messaging',
                redirect: () => titlePageRedirect('PushMessaging')
            },
            {
                name: "RichInAppMessagingBase",
                path: '/promotions/rich-inapp-messaging',
                redirect: () => titlePageRedirect('RichInAppMessaging')
            },
            {
                name: "HomepageBannersBase",
                path: '/promotions/homepage-banners',
                redirect: () => titlePageRedirect('HomepageBanners')
            },
            {
                name: "AppSimulatorBase",
                path: '/promotions/layout',
                redirect: () => titlePageRedirect('AppSimulator')
            },
            {
                name: "CampaignLinkBuilderBase",
                path: '/marketing/campaign-link-builder',
                redirect: () => titlePageRedirect('CampaignLinkBuilder')
            },
            {
                name: "LeadGenerationBase",
                path: '/marketing/lead-generation',
                redirect: () => titlePageRedirect('LeadGeneration')
            },
            {
                name: "ScreenshotGeneratorBase",
                path: '/promotions/screenshot-generator',
                redirect: () => titlePageRedirect('ScreenshotGenerator')
            },
            {
                name: "IngestSubscribersBase",
                path: '/ingest-subscribers',
                redirect: () => titlePageRedirect('IngestSubscribers')
            },
        ]
    }
    //MAIN ROUTES
]

function titlePageRedirect(name) {

    var defaultId = store.state.defaultTitle === null ? null : store.state.defaultTitle.id;
    var props = router.resolve({
        name: name,
        params: { titleId: defaultId },
    });
    return props.href;
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    //check if saved?
    if (to.name !== 'LoginView') {
        store.commit('asideMobileStateToggle', store.state.isAsideMobileExpanded)
        document.getElementById('app').classList.add(['has-navbar-fixed-top'])
        document.body.classList.add(['has-navbar-fixed-top'])
    } else {
        document.getElementById('app').classList.remove(['has-navbar-fixed-top'])
        document.body.classList.remove(['has-navbar-fixed-top'])
    }
    if (to.path !== from.path && store.state.hasUnsavedChanges && !window.confirm('Do you really want to leave? you have unsaved changes!')) {
        next(false); //unsaved changes which they didnt want to continue
    } else { 
        store.dispatch("setPageHasChanges", false);

        //store.state.hasUnsavedChanges = false;

        //check permissions
        var role = null
        const token = document.cookie

        var user = store.state.user;

        if (user == null) {
            if (token !== '' && document.cookie.indexOf('role') > 0) {
                role = document.cookie.split('; ').find(row => row.startsWith('role')).split('=')[1]
            }
        } else {
            role = user.role
        }

        if (to.meta === undefined || to.meta.authType === undefined) { //no auth for redirects so carry on
            next()
        } else if (to.meta.authType.includes(role)) {
            next()
        } else if (token !== '' && (to.name === 'LoginView' || to.name === 'ForgotPassword')) {
            next()
        } else if (role === null || role === undefined) {
            (to.meta.noAuth) ? next() : router.push({ name: 'LoginView', query: { redirecturl: to.fullPath } })
        }
        else {
            //window.location.href = process.env.VUE_APP_LANDINGBASEURL
            //router.push({ name: 'Login' })n#
            document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); })
        }
    }

})

export default router
