<template>
    <div id="app">
        <nav-bar class="navigation-top" v-if="user" />
        <div id="sidebar-menu" class="sidebar-menu" v-if="user">
            <side-navigation-menu :menu="menu" />
        </div>
        <help-icon></help-icon>

        <router-view />
        <help-and-support-modal v-if="showSupportDialog" v-on:close="closeSupportModel"></help-and-support-modal>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NavBar from '@/components/molecules/NavBar'
    import SideNavigationMenu from '@/components/molecules/SideNavigationMenu'
    import HelpAndSupportModal from '@/components/organisms/HelpAndSupportModal'
    import HelpIcon from '@/components/atoms/HelpIcon.vue'

    import permissionsMixin from '@/mixins/permissions'

    export default {
        name: 'MainView',
        components: { NavBar, SideNavigationMenu, HelpAndSupportModal, HelpIcon },
        mixins: [permissionsMixin],
        data() {
            return {
                isClientUserLoginModalActive: false
            }
        },
        computed: {
            menu() {
                return [
                    {
                        isVisible: true,
                        label: 'General',
                        name: 'General',
                        menu: [
                            {
                                to: { name: 'Dashboard' },
                                label: this.user.role === 'financial' ? 'Finance Dashboard' : this.user.role === 'customerservice' ? 'Customer Dashboard' : 'Magazine Dashboard', //TODO: For finaical this might need another name?
                            },
                            {
                                to: { name: 'NewsFeed' },
                                label: 'News',
                            },
                            {
                                to: { name: 'OldPortalRedirect' }, //switch to redirect from OfferCreator
                                label: 'Legacy Site',
                                legacy: true
                            },
                        ]
                    },
                    {
                        isVisible: this.CanAccessMagazines && this.user.role !== 'customerservice',
                        label: 'My Magazines',
                        name: 'MyMagazines',
                        menu: [
                            {
                                isVisible: this.CanAccessMagazines,
                                to: { name: 'MyIssuesBase' },
                                label: 'Issues',
                            },
                            {
                                isVisible: this.CanAccessMagazines,
                                to: { name: 'EditScheduleBase' },
                                label: 'Schedule',
                            },
                            {
                                isVisible: this.CanAccessMagazines || !this.CanAccessCustomerAdmin,
                                to: { name: 'CustomerAdmin' },
                                label: 'Customer Admin',
                            },
                            {
                                isVisible: this.CanAccessSiteAdmin || (this.CanAccessMagazines && this.CanAccessBulkIssueUploader),
                                to: { name: 'BulkUploader' },
                                label: 'Bulk Issue Uploader',
                            },
                            {
                                isVisible: this.CanAccessSiteAdmin || this.CanAccessMagazines,
                                to: { name: 'IngestSubscribersBase' },
                                label: 'Ingest Subscribers',
                            },
                        ]
                    },
                    {
                        isVisible: this.CanAccessMagazines || this.CanAccessPromotions,
                        label: 'Marketing',
                        name: 'Marketing',
                        menu: [
                            {
                                isVisible: this.CanAccessMagazines,
                                to: { name: 'OfferCreatorRedirect' }, //switch to redirect from OfferCreator
                                label: 'Offer Creator',
                                legacy: true
                            },
                            {
                                isVisible: this.CanAccessMagazines,
                                to: { name: 'LeadGenerationBase' },
                                label: 'Lead Generation',
                            },
                            {
                                isVisible: this.CanAccessPromotions,
                                to: { name: 'CampaignLinkBuilderBase' },
                                label: 'Campaign Link Builder',
                            },
                            {
                                isVisible: this.CanAccessMagazines,
                                to: { name: 'AffiliateProgram' },
                                label: 'Affiliate Program',
                            },
                        ]
                    },
                    {
                        isVisible: this.CanAccessPromotions,
                        label: 'Branded App',
                        name: 'BrandedApp',
                        menu: [
                            {
                                to: { name: 'PushMessagingBase' },
                                label: 'Push Messaging',
                            },
                            {
                                to: { name: 'RichInAppMessagingBase' },
                                label: 'In App Messaging',
                            },
                            {
                                to: { name: 'HomepageBannersBase' },
                                label: 'Homepage Banners',
                            },
                            {
                                to: { name: 'AppSimulatorBase' },
                                label: 'Homepage Layout',
                            },
                            //{
                            //    to: { name: 'ScreenshotGeneratorBase' },
                            //    label: 'Screenshot Generator',
                            //},
                        ]
                    },
                    {
                        isVisible: this.CanAccessPromotions,
                        label: 'Resources & Support',
                        name: 'Resources',
                        menu: [
                            {
                                isVisible: true,
                                to: { name: 'FeaturesView' },
                                label: 'Features',
                            },
                            {
                                isVisible: true,
                                to: { name: 'LogosArtworkView' },
                                label: 'Logos & Artwork',
                            },

                            {
                                isVisible: true,
                                help: true,
                                label: 'Help & Contact Us',
                            },
                        ]
                    },
                    {
                        isVisible: this.CanAccessFinanceReports || this.CanAccessAnalyticReports || this.CanAccessFinancial,
                        label: 'Payments & Reports',
                        name: 'Reports',
                        menu: [
                            {
                                isVisible: this.CanAccessFinanceReports,
                                to: { name: 'AllReportsView' },
                                label: 'Sales Reports',
                                legacy: true
                            },
                            //{
                            //    isVisible: this.CanAccessAnalyticReports,
                            //    to: { name: 'AllReports' },
                            //    label: 'Analytic Reports',
                            //},
                            {
                                isVisible: this.user.role !== "financial",
                                to: { name: 'StatementsView' },
                                label: 'Statements',
                            },
                        ]
                    },
                    {
                        isVisible: this.CanAccessSiteAdmin,
                        label: 'Admin',
                        name: 'Admin',
                        menu: [
                            {
                                to: { name: 'CategoryManagement' },
                                label: 'Category Management',
                            },
                        ]
                    },
                ];
            },

            ...mapState([
                'user', 'showSupportDialog'
            ]),
        },
        methods: {
            closeSupportModel() {
                this.$store.commit('setSupportDialog', false);
            }
        }
    };
</script>
