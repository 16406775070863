<template>
    <div>

        <div class="modal is-active">
            <div class="modal-background"></div>

            <div class="modal-card " style="width: 1000px;max-width: 90%">
                <header class="modal-card-head">
                    <p class="modal-card-title">Help &amp; Support: {{ route.meta.title }}</p>
                    <button class="delete" aria-label="close" v-on:click="closeDialog"></button>
                </header>
                <section class="modal-card-body p-0" >
                    <div class="is-flex" style="max-height:100%">
                        <div class="p-5 content mb-0" style="flex:2;overflow: auto;"  v-if="!showingAllFaqs">
                            <h5 class="title is-4 ">Frequently Asked Questions for this page:</h5>
                            <template v-if="pageFaqs && pageFaqs.length > 0">
                                <div v-for="f in pageFaqs" :key="f.id" class="mt-5">
                                    <div class="has-text-weight-bold mb-2" v-html="f.question"></div>
                                    <p class="answer" v-html="f.answer"></p>
                                </div>
                                <div class="mt-5">
                                    <div class="has-text-weight-bold mb-1">Can't find the question you're looking for?</div>
                                    <p class="answer">
                                        <a @click="showingAllFaqs = true">Click here</a> to view the FAQs for every page, or Contact Support using the button below.
                                    </p>
                                </div>

                            </template>
                            <p v-else>No FAQs found for this page - <a @click="showingAllFaqs = true">Click to view all FAQs</a></p>
                        </div>
                        <div class="p-5 content mb-0" style="flex: 2; overflow: auto;" v-else>
                            <h5 class="title is-4">Frequently Asked Questions for the site:</h5>

                            <div v-for="type in faqTypes" :key="type.id">
                                <h5 class="title is-5  mt-5 ">{{ getPageNameByType(type) }}</h5>
                                <div v-for="f in getFaqsByType(type)" :key="f.id" class="mt-5">
                                    <div class="has-text-weight-bold mb-2"><span v-html="f.question"></span></div>
                                    <p class="answer" v-html="f.answer"></p>
                                </div>
                                <hr />

                            </div>
                            <div>
                                <div class="has-text-weight-bold mb-2" >Want to see just FAQs for this page?</div>
                                <p class="answer"><a @click="showingAllFaqs = false">Click here</a> to see just this pages FAQs</p>
                            </div>
                        </div>

                        <div  class="p-5 ml-3" style="flex: 1; overflow: auto;">
                            <h5 class="title is-5 ">Downloads / Resources</h5>
                            <ul>
                                <li v-for="(download, index) in downloads" :key="index" class="mb-1">
                                    <a :href="download.link" target="_blank"><b-icon :icon="download.icon" style="margin-top:5px;margin-right:10px;"></b-icon>{{ download.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </section>
                <footer class="modal-card-foot is-justify-content-flex-end">
                    <span class="mr-3 has-text-weight-bold">Can't find the answer you need?</span>
                    <button class="button is-primary" @click="OpenFeedback()">
                    
                        <span class="icon">
                            <i class="mdi mdi-email"></i>
                        </span>
                        <span>Contact Support</span>
                    </button>
                </footer>

            </div>

        </div>

        <feedback-modal v-if="showFeedbackModal" v-on:submit="closeDialog" v-on:close="showFeedbackModal = false"></feedback-modal>

    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import baseMixin from '@/mixins/mixin'
    import FeedbackModal from '@/components/organisms/FeedbackModal'

    export default {
        name: 'HelpAndSupportModal',
        components: {
            FeedbackModal
        },
        mixins: [baseMixin],
        props: {
        },
        data() {
            return {
                showingAllFaqs: false,
                showFeedbackModal: false,
                isLoading: false,
                downloads: [
                    { name: 'Schedule Template', icon: 'file-excel', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/schedule-template.xlsx' },
                    { name: 'PDF specification & Uploading Guide', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/how-to-upload-guide.pdf' },
                    //{ name: 'Promo Vouchers PDF', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/PROMO_Vouchers.pdf' },
                    //{ name: 'Adding Embedded Html in the Multimedia Picker', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/adding_embedded_html.pdf' },
                    //{ name: 'Complete Your Collection', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/complete_your_collection_v2.pdf' },
                    //{ name: 'Replica Edition Walkthrough', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/replica_edition_walkthrough.pdf' },
                    { name: 'Multimedia Picker Walkthrough', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/picker_2017-06-05.pdf' },
                    //{ name: 'Digital PDF creation', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/digital_pdf_creation.pdf' },
                    //{ name: 'Custom Build Walkthrough', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/custom_build_v2_walkthrough.pdf' },
                    //{ name: 'App Promotions Area Walkthrough', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/PROMO-AppPromotionsArea(Sept2015).pdf' },
                    { name: 'New Title Info Sheet', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/app-infosheet-single.docx' },
                    { name: 'New Title Info Sheet (Pocketmags only)', icon: 'file-word', link: 'https://mcdatastore.blob.core.windows.net/portalfiles/documents/pocketmags-infosheet-single.docx' },
                    { name: 'App Store Optimization', icon: 'file-pdf-box', link: 'https://mcgeneral.blob.core.windows.net/portal/branded-app-update-optimisation.pdf' },
                    { name: 'Mobile Edition Styling', icon: 'file-excel', link: 'https://mcgeneral.blob.core.windows.net/portal/MobileEditionStylingSheet.xlsx' },
                    { name: 'App Graphic Spec', icon: 'file-word', link: 'https://docs.google.com/document/d/1mYMUhm53FVyDK_jwnUOlJ2bOiurGXoP0mWGxmUa7OsM/edit#' },
                    { name: 'Marketing your digital edition and apps', icon: 'file-pdf-box', link: 'https://mcdatastore.blob.core.windows.net:443/portalfiles/documents/marketing_your_digital_edition_and_apps.pdf' },
                ],
            }
        },
        created() {
        this.$store.dispatch('loadFaqContent', this.$axiosAPI);
        },
        computed: {
            ...mapState([
                'faqContent'
            ]),
            pageFaqs() {
                var vm = this;
                if (this.faqContent === null)
                    return null;

                return this.faqContent.filter(f => f.type.toLowerCase() === vm.$route.name.toLowerCase());
            },
            faqTypes() {
                const unique = (value, index, self) => {
                    return self.indexOf(value) === index
                }
                return this.faqContent.map(f => f.type).filter(unique);
            },

            route() {
                console.log(this.$route);
                return this.$route;
            }
        },
        methods: {
            closeDialog() {
                this.$emit('close');
            },
            OpenFeedback() {
                this.showFeedbackModal = true;
            },
            getPageNameByType(routeName) {
                var matchRoute = this.$router.options.routes[2].children.filter(f => f.name.toLowerCase() === routeName.toLowerCase());
                return matchRoute.length > 0 ? matchRoute[0].meta.title : routeName;
            },
            getFaqsByType(type) {
                return this.faqContent.filter(f => f.type == type);
            }
        }
    }
</script>