<template>
    <div class="mr-4 mt-2" style="position: absolute; cursor: pointer; right: 0;bottom: 10px; z-index: 13;" @click="OpenHelpAndSupport">
        <b-icon icon="help-circle" type="is-info" size="is-large"></b-icon>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'HelpIcon',
        components: { },
        data() {
            return {
            }
        },
        computed: {
            ...mapState(['user', 'showSupportDialog']),
        },
        methods: {
            OpenHelpAndSupport() {
                this.$store.commit('setSupportDialog', true);
            }
        }
    };
</script>